<template>
  <div>
    <SettingsNavigation :links="nestedLinks" />

    <div class="Settings__Children">
      <Authorize :permission="$route.meta.permission || true">
        <router-view />
      </Authorize>
    </div>
  </div>
</template>

<script>
import Authorize from '@/components/core/Authorize.vue'

import SettingsNavigation from '@/pages/settings/components/SettingsNavigation.vue'

export default {
  components: {
    Authorize,
    SettingsNavigation
  },

  computed: {
    nestedLinks () {
      return [
        { label: this.$t('pages.settings.billing.navigation.billing'), to: { name: 'settings.billing.billing' }, icon: 'billing' },
        { label: this.$t('pages.settings.billing.navigation.invoices'), to: { name: 'settings.billing.invoices' }, icon: 'invoices' },
        { label: this.$t('pages.settings.billing.navigation.plans'), to: { name: 'settings.billing.plans' }, icon: 'puzzle' }
      ]
    }
  }
}
</script>
